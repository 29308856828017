<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <!-- 产品名 -->
          <el-form-item label="订单：" class="form-item-b0">
            <el-input
              v-model="query.search"
              placeholder="请输入订单"
              size="small"
              clearable
              @keyup.native.enter="getList('place')"
            ></el-input>
          </el-form-item>

          <el-form-item label="状态：" class="form-item-b0">
            <el-select v-model="query.status" placeholder="请选择状态">
              <el-option label="已发货" :value="2"></el-option>
              <el-option label="未发货" :value="1"></el-option>
              <el-option label="未支付" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
          <el-button type="success" size="mini" @click="addOrder">添加产品</el-button>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <!-- <el-table-column prop="ProductId" label="产品Id" align="center"></el-table-column> -->

      <el-table-column prop="Name" label="名称" align="center"></el-table-column>

      <!-- <el-table-column prop="Title" label="标题" align="center"></el-table-column> -->

      <el-table-column prop="Price" label="价格" align="center"></el-table-column>

      <el-table-column prop="Integral" label="积分" align="center"></el-table-column>

      <el-table-column prop="LogisticsName" label="物流" align="center">
        <template slot-scope="scope">
          <!-- <el-input v-model="scope.row.LogisticsName" placeholder="物流"></el-input> -->
          <el-select v-model="scope.row.LogisticsName" filterable placeholder="请选择物流">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column prop="LogisticsNo" label="单号" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.LogisticsNo" placeholder="单号"></el-input>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)"> 修改信息 </el-button>

          <el-button style="margin-top: 6px" type="success" size="mini" @click="onEditInfo(scope.row, 1)">
            发货
          </el-button>

          <el-button style="margin-top: 6px" type="danger" size="mini" @click="onDelete(scope.row.OrderId)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 商户管理 -->
    <el-dialog
      :visible.sync="addDialog"
      class="FormDialogBox"
      width="680px"
      title="添加产品"
      :close-on-click-modal="false"
      append-to-body
      @close="addDialog = false"
    >
      <el-form label-width="auto" :model="addForm" ref="ruleForm">
        <el-form-item label="产品Id">
          <el-input v-model="addForm.productId" placeholder="产品Id"></el-input>
        </el-form-item>

        <el-form-item label="数量">
          <el-input v-model="addForm.count" placeholder="数量"></el-input>
        </el-form-item>

        <el-form-item label="价格">
          <el-input v-model="addForm.price" placeholder="价格"></el-input>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="addForm.remark" placeholder="备注"></el-input>
        </el-form-item>

        <el-form-item label="物流名称">
          <el-input v-model="addForm.logisticsName" placeholder="物流名称"></el-input>
        </el-form-item>

        <el-form-item label="物流单号">
          <el-input v-model="addForm.logisticsNo" placeholder="物流单号"></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="发货时间">
            <!-- <el-input v-model="addForm.logisticsTime" placeholder="发货时间"></el-input> -->
            <el-date-picker
              v-model="addForm.logisticsTime"
              type="date"
              placeholder="发货时间"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="收货时间" style="margin-left: 20px">
            <el-date-picker
              v-model="addForm.receiveTime"
              type="date"
              placeholder="收货时间"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </div>

        <el-form-item label="收货人">
          <el-input v-model="addForm.receiveName" placeholder="收货人"></el-input>
        </el-form-item>

        <el-form-item label="收货电话">
          <el-input v-model="addForm.receivePhone" placeholder="收货电话"></el-input>
        </el-form-item>

        <el-form-item label="收货地址">
          <el-input v-model="addForm.receiveAddress" placeholder="收货地址"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="danger" @click="addDetermine()"> 确 定 </el-button>
      </div>
    </el-dialog>

    <div class="pre_img" v-if="dialogVisible">
      <i class="el-icon-circle-close icon" @click="imgClose"></i>
      <el-image class="img" style="width: 20%; display: block; margin: 0 auto" :src="dialogImageUrl"></el-image>
    </div>
  </div>
</template>

<script>
import { dataList } from './js/logisticsList.js'
console.log(dataList)

export default {
  name: 'membersIndex',
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,

      list: [],

      addDialog: false,
      addForm: {
        id: 0,
        count: 1,
        price: 0,
        remark: '',
        productId: 0,
        logisticsName: '',
        logisticsNo: '',
        logisticsTime: '',
        receiveName: '',
        receivePhone: '',
        receiveAddress: '',
        receiveTime: '',
        payType: '1'
      },

      loading: true,
      total: 0,

      query: {
        pageindex: 1,
        pagesize: 20,
        search: '',
        status: 1
      },

      options: dataList
    }
  },

  created() {
    this.getList('place')
  },

  computed: {
    myImage: function () {
      const arr = []
      if (this.addForm.ImgUrl) {
        const list = this.addForm.ImgUrl.split(',')
        list.forEach((item) => {
          if (item) {
            arr.push({
              url: item
            })
          }
        })
      }
      return arr
    }
  },

  methods: {
    // 获取列表
    async getList(place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        await this.$nextTick()
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      }

      console.log(this.query)

      try {
        const { data: res } = await this.$api.order.list(this.query)
        console.log('获取订单列表', res)

        this.list = res.list

        this.loading = false
        this.list = res.list
        this.total = res.count
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },

    onEditInfo(data, type) {
      const addForm = {
        id: data.OrderId,
        count: data.FCount,
        price: data.Price,
        remark: data.Remark,
        productId: data.ProductId,
        logisticsName: data.LogisticsName,
        logisticsNo: data.LogisticsNo,
        logisticsTime: data.LogisticsTime,
        receiveName: data.ReceiveName,
        receivePhone: data.ReceivePhone,
        receiveAddress: data.ReceiveAddress,
        receiveTime: data.ReceiveTime
      }
      this.addForm = addForm

      if (type) {
        this.addDetermine(type)
        return
      }

      this.addDialog = true
    },

    async onDelete(id) {
      this.$confirm('是否删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$api.order.delete({
          id
        })
        console.log('删除产品', data)

        this.$message.success('删除成功')
        this.getList()
      })
    },

    addOrder() {
      this.addForm = {
        id: 0,
        count: 1,
        price: 0,
        remark: '',
        productId: 0,
        logisticsName: '',
        logisticsNo: '',
        logisticsTime: '',
        receiveName: '',
        receivePhone: '',
        receiveAddress: '',
        receiveTime: ''
      }

      this.addDialog = true
    },

    async addDetermine(type) {
      console.log('addForm', this.addForm)
      const addForm = {
        ...this.addForm
      }

      if (!addForm.logisticsTime) {
        delete addForm.logisticsTime
      }

      if (!addForm.receiveTime) {
        delete addForm.receiveTime
      }

      if (type) {
        if (!addForm.logisticsName || !addForm.logisticsName) {
          this.$message.error('请填写物流')
          return
        }

        addForm.logisticsTime = new Date()
      }

      delete addForm.price
      console.log('addForm', addForm)

      const { data } = await this.$api.order.add(addForm)
      console.log('添加产品', data)

      this.addDialog = false
      this.getList()
    },

    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      console.log(file.url)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    imgClose() {
      this.dialogImageUrl = ''
      this.dialogVisible = false
    },

    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .data-list {
  width: 620px !important;

  ul.el-scrollbar__view {
    display: flex;
    flex-wrap: wrap;
  }
}

.auditImg {
  display: flex;
  .block {
    margin-right: 25px;
    span {
      display: block;
      width: 120px;
      text-align: center;
    }
    img {
      width: 120px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.pre_img {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999988;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 60px;
    top: 60px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  .img {
    width: 20%;
    display: block;
  }
}
</style>
