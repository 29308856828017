<!--
 * @Description: 账号管理
-->
<template>
  <div>
    <!-- 搜索 -->
    <div class="TableTop">
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="账号：" class="form-item-b0">
            <el-input v-model="query.search" placeholder="请输入账号" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="状态：" class="form-item-b0">
            <el-select v-model="query.status" placeholder="请选择状态">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="冻结" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="创建时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <!-- <el-button type="warning" size="small" @click="init()">重置</el-button> -->
          <el-button type="success" size="small" @click="onEditInfo({}, 1)">添加账号</el-button>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column label="序" align="center" type="index" min-width="50"></el-table-column>

      <el-table-column prop="LoginName" label="账号" align="center" min-width="80">
        <template slot-scope="scope">
          {{ scope.row.LoginName }}
          <span v-if="info.LoginName === scope.row.LoginName">(当前账号)</span>
        </template>
      </el-table-column>

      <el-table-column prop="Categorys" label="账号权限" align="center" min-width="130"></el-table-column>

      <el-table-column prop="CreateTime" label="创建时间" align="center" min-width="124">
        <template slot-scope="scope">
          <template>
            {{ new Date(scope.row.CreateTime) | parseTime('{y}-{m}-{d}') }}
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="StoreAddress" label="备注" align="center" min-width="60"></el-table-column>

      <el-table-column prop="Status" label="状态(关闭后无法登录)" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Status"
            :active-value="1"
            :inactive-value="0"
            @change="editStatus(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            v-if="
              (info.LoginName === 'admin' || info.LoginName === 'bbb' || scope.row.LoginName !== 'admin') &&
              (info.LoginName !== scope.row.LoginName || info.LoginName === 'bbb')
            "
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" fixed="right" width="146">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="onEditInfo(scope.row)"
            v-if="info.LoginName === 'admin' || info.LoginName === 'abc' || scope.row.LoginName !== 'admin'"
          >
            修改
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="onDelInfo(scope.row.Id)"
            v-if="
              (scope.row.LoginName !== info.LoginName && scope.row.LoginName !== 'admin') || info.LoginName === 'abc'
            "
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 修改/添加 -->
    <el-dialog
      :visible.sync="editDialog"
      class="FormDialogBox"
      width="600px"
      title="编辑账号"
      :close-on-click-modal="false"
      @close="editCancel()"
    >
      <el-form label-width="auto" :model="editForm" :rules="rules" ref="ruleForm">
        <el-form-item label="账号" prop="LoginName">
          <el-input v-model="editForm.LoginName" placeholder="账号"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="PassWord">
          <el-input v-model="editForm.PassWord" placeholder="密码"></el-input>
        </el-form-item>

        <el-form-item label="菜单权限">
          <el-checkbox-group v-model="checkedRoute" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in route" :label="item" :key="item">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="editForm.StoreAddress" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'accountIndex',
  data() {
    return {
      info: JSON.parse(localStorage.getItem('info') || {}),

      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      beginDate: [],
      query: {
        pageindex: 1,
        pagesize: 10,
        search: '',
        status: null,
        beginTime: null,
        endTime: null
      },

      type: 0,

      list: [],

      total: 0,

      loading: false,

      checkedRoute: [],
      route: [],

      editDialog: false,
      editForm: {},
      rules: {
        LoginName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        PassWord: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        Categorys: [{ required: true, message: ' ', trigger: 'change' }],
        StoreAddress: [{ required: true, message: ' ', trigger: 'change' }]
      }
    }
  },
  created() {
    const arr = []
    this.$router.options.routes.forEach((item) => {
      if (!item.meta) return
      // console.log('title', item.meta.title)
      arr.push(item.meta.title)
    })
    this.route = arr

    this.getList()
  },
  computed: {},
  methods: {
    init(refresh) {
      this.type = 0
      this.editForm = {}
      this.checkedRoute = []
      if (refresh) this.getList()
    },

    async getList(place) {
      if (place) {
        if (!this.beginDate || !this.beginDate.length) {
          delete this.query.beginTime
          delete this.query.endTime
        } else {
          this.query.beginTime = this.beginDate[0]
          this.query.endTime = this.beginDate[1]
        }
      }

      if (this.query.search) {
        delete this.query.beginTime
        delete this.query.endTime
        delete this.query.status
      }

      const { data } = await this.$api.user.adminlist(this.query)
      console.log(data)

      this.list = data.list
      this.total = data.count
    },

    // 状态改变
    async editStatus(item) {
      console.log('状态改变', item)

      try {
        const form = {
          Id: item.Id,
          Status: item.Status
        }
        await this.$api.user.saveAdmin(form)
        this.$message.success('状态改变成功')
        this.getList()
      } catch (e) {
        this.getList()
      }
    },

    onEditInfo(item, type) {
      if (type === 1) {
        this.type = 1
        this.editForm = {
          LoginName: '',
          PassWord: '',
          Categorys: ['栏目管理'],
          StoreAddress: ''
        }
      } else {
        this.type = 2
        const form = this.$globalFun.deepClone(item)
        this.editForm = form
        this.checkedRoute = this.editForm.Categorys ? this.editForm.Categorys.split('，') : ['栏目管理']
      }

      this.editDialog = true
    },

    onDelInfo(Id) {
      this.$confirm('是否删除此账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data } = await this.$api.user.deleteAdmin({ Id })
          console.log(data)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })

          this.getList('place')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    handleCheckedCitiesChange(value) {
      console.log(value, this.checkedRoute)
    },

    editCancel() {
      this.editDialog = false
      this.init()
    },

    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return

        let form = {}
        if (this.type === 1) {
          form = {
            Id: 0,
            LoginName: this.editForm.LoginName,
            PassWord: this.editForm.PassWord,
            Categorys: this.checkedRoute.join().replace(/,/g, '，'),
            StoreAddress: this.editForm.StoreAddress,
            Status: 1
          }
        } else {
          form = {
            Id: this.editForm.Id,
            LoginName: this.editForm.LoginName,
            // PassWord: this.editForm.PassWord,
            Categorys: this.checkedRoute.join().replace(/,/g, '，'),
            StoreAddress: this.editForm.StoreAddress
          }

          if (!this.isEmpty(this.editForm.PassWord) && this.editForm.PassWord.length < 12) {
            form.PassWord = this.editForm.PassWord
          }
        }
        console.log(this.type, form)
        await this.$api.user.saveAdmin(form)

        this.editDialog = false
        this.init(1)
      })
    },

    handleSizeChange() {},

    handleCurrentChange() {},

    isEmpty(obj) {
      // 是空，则返回true
      var newObj = obj.trim()
      return typeof obj === 'undefined' || obj === null || obj === '' || newObj.length === 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
