export const dataList = [
  {
    value: '顺丰快递',
    label: '顺丰快递'
  },
  {
    value: '京东快递',
    label: '京东快递'
  },
  {
    value: 'EMS',
    label: 'EMS'
  },
  {
    value: '中通快递',
    label: '中通快递'
  },
  {
    value: '韵达快递',
    label: '韵达快递'
  },
  {
    value: '圆通速递',
    label: '圆通速递'
  },
  {
    value: '申通快递',
    label: '申通快递'
  },
  {
    value: '极兔速递',
    label: '极兔速递'
  },
  {
    value: '德邦快递',
    label: '德邦快递'
  },
  {
    value: '跨越速运',
    label: '跨越速运'
  },
  {
    value: '国通快递',
    label: '国通快递'
  },
  {
    value: '宅急送',
    label: '宅急送'
  },
  {
    value: '天天快递',
    label: '天天快递'
  }
]
