<template>
  <div class="navbar">
    <div class="left-menu">
      <!-- 收缩 -->
      <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
      <!-- 面包屑 -->
      <breadcrumb class="breadcrumb-container" />
    </div>
    <div class="right-menu">
      <!-- 昵称 -->
      <div class="menu-name">{{ info.LoginName }}</div>
      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img
            src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80"
            class="user-avatar"
          />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="logout">
            <span style="display: block">登出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- 昵称 -->
      <div class="menu-name" style="cursor: pointer" @click="logout">退出登录</div>
    </div>

    <div class="right-mid">
      <span style="color: blue; margin-right: 10px; cursor: pointer" @click="shuaxin">刷新</span>
      <span style="margin-right: 20px">待审核:</span>
      <span v-if="allCoumt.storeCount"
        >商户:<span style="color: red; margin-right: 10px">{{ allCoumt.storeCount }}</span></span
      >
      <span v-if="allCoumt.articleCount"
        >发布:<span style="color: red; margin-right: 10px">{{ allCoumt.articleCount }}</span></span
      >
      <span v-if="allCoumt.goodsCount"
        >商品:<span style="color: red; margin-right: 10px">{{ allCoumt.goodsCount }}</span></span
      >
      <span v-if="allCoumt.imageCount"
        >相册:<span style="color: red; margin-right: 10px">{{ allCoumt.imageCount }}</span></span
      >
      <span v-if="allCoumt.provinceCount"
        >同省:<span style="color: red; margin-right: 10px">{{ allCoumt.provinceCount }}</span></span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from './Breadcrumb'
import Hamburger from './Hamburger'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      info: this.$store.state.user.info,
      allCoumt: {},
      timer: null
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar'])
  },
  created() {
    this.getNum()
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.getNum()
    }, 1000 * 60)
  },
  methods: {
    // 是否收缩侧边栏
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },

    async getNum() {
      const { data } = await this.$api.members.auditcount()
      // console.log('1111111', data)
      this.allCoumt = data
      // if (data.storeCount > 0 || data.articleCount > 0 || data.imageCount > 0 || data.provinceCount > 0) {

      // }
      // if (data.storeCount > 0 || data.articleCount > 0 || data.imageCount > 0 || data.provinceCount > 0 || data.goodsCount > 0) {
      //   // const mp3 = '../../assets/prompt.mp3'
      //   const mp3 = new Audio('https://api.chajiuqu.com/uploadImage/img/prompt.mp3')
      //   mp3.play()
      //   // console.log('mp3', mp3)
      //   setTimeout(() => {
      //     mp3.pause()
      //     // console.log('mp3,stop')
      //   }, 2000)
      // }
    },

    // 登出
    async logout() {
      await this.$store.dispatch('user/logout', 'api')
      this.$router.push('/login')
      this.$notify({
        title: '提示',
        message: '已登出',
        type: 'success'
      })
    },

    shuaxin(src) {
      history.go(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .right-mid {
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    right: 200px;
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    height: 100%;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .menu-name {
      margin-right: 20px;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
