<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <el-form-item label="" class="form-item-b0">
            <el-button :type="checkBtnCurrent == 0 ? 'danger' : ''" size="small" @click="getList('place', 0)"
              >删除</el-button
            >
            <el-button :type="checkBtnCurrent == 1 ? 'success' : ''" size="small" @click="getList('place', 1)"
              >审核通过</el-button
            >
            <el-button :type="checkBtnCurrent == 2 ? 'primary' : ''" size="small" @click="getList('place', 2)"
              >提审中</el-button
            >
            <el-button :type="checkBtnCurrent == 4 ? 'info' : ''" size="small" @click="getList('place', 4)"
              >已退回</el-button
            >
            <el-button :type="checkBtnCurrent == 3 ? 'warning' : ''" size="small" @click="getList('place', 3)"
              >私密</el-button
            >
          </el-form-item>

          <el-form-item label="手机号码：" class="form-item-b0">
            <el-input v-model="query.loginName" placeholder="请输入手机号码" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="商品名称：" class="form-item-b0">
            <el-input v-model="query.name" placeholder="请输入商品名称" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="分类：" class="form-item-b0">
            <el-select v-model="query.category1" placeholder="请选择分类">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="烟盒" value="香烟"></el-option>
              <el-option label="酒类" value="酒类"></el-option>
              <el-option label="茶叶" value="茶叶"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="上传时间：" class="form-item-b0">
            <el-date-picker
              v-model="beginDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="LoginName" label="手机号码" align="center"></el-table-column>

      <el-table-column prop="StoreName" label="商铺名称" align="center"> </el-table-column>

      <el-table-column prop="Name" label="商品名称" align="center"></el-table-column>

      <el-table-column prop="Date" label="发布时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.Date) | parseTime }}
        </template>
      </el-table-column>

      <el-table-column prop="UpdateTime" label="修改时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.UpdateTime) | parseTime }}
        </template>
      </el-table-column>

      <el-table-column prop="Status" label="公开/隐私" align="center">
        <template slot-scope="scope">
          {{ scope.row.Status === 3 ? '私密' : '公开' }}
        </template>
      </el-table-column>

      <el-table-column prop="BrowseCount" label="浏览量" align="center"></el-table-column>

      <el-table-column prop="Status" label="状态" align="center">
        <template slot-scope="scope">
          {{ status[scope.row.Status].text }}
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button :type="status[scope.row.Status].type" size="mini" @click="onEditInfo(scope.row)">
            {{ status[scope.row.Status].text }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 商户管理 -->
    <el-dialog
      :visible.sync="editDialog"
      class="FormDialogBox"
      width="800px"
      title="商品发布管理"
      top="5vh"
      :close-on-click-modal="false"
      @close="editCancel()"
    >
      <div class="tabs">
        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
          <el-tab-pane label="商品发布" name="商品发布"></el-tab-pane>
          <el-tab-pane label="发布历史" name="发布历史"></el-tab-pane>
          <el-tab-pane label="商户资料" name="商户资料"></el-tab-pane>
          <el-tab-pane label="留言" name="留言"></el-tab-pane>
          <el-tab-pane label="关注" name="关注"></el-tab-pane>
          <el-tab-pane label="粉丝" name="粉丝"></el-tab-pane>
        </el-tabs>
      </div>

      <template v-if="activeName === '商品发布'">
        <el-form label-width="auto" :model="editForm" ref="ruleForm">
          <div style="display: flex">
            <!-- <el-form-item label="类型">
            <el-input v-model="editForm.Category1" placeholder="类型"></el-input>
          </el-form-item> -->
            <el-form-item label="类型">
              <el-select v-model="editForm.Category1" placeholder="请选择分类">
                <el-option label="烟盒" value="香烟"></el-option>
                <el-option label="酒类" value="酒类"></el-option>
                <el-option label="茶叶" value="茶叶"></el-option>
                <el-option label="其他" value="其他"></el-option>
                <el-option label="首发" value="首发"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="商品名">
              <el-input v-model="editForm.Name" placeholder="类型"></el-input>
            </el-form-item>

            <el-form-item label="浏览数">
              <el-input v-model="editForm.BrowseCount" placeholder="浏览数"></el-input>
            </el-form-item>
          </div>

          <el-form-item label="更多描述">
            <el-input v-model="editForm.Remark" type="textarea" autosize placeholder="更多描述"></el-input>
          </el-form-item>

          <div style="display: flex">
            <el-form-item label="条码">
              <el-input v-model="editForm.Code" placeholder="条码"></el-input>
            </el-form-item>

            <el-form-item label="盒码">
              <el-input v-model="editForm.BoxCode" placeholder="盒码"></el-input>
            </el-form-item>

            <el-form-item label="年份">
              <el-input v-model="editForm.Year" placeholder="年份"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="售价">
              <el-input v-model="editForm.Price" placeholder="售价"></el-input>
            </el-form-item>

            <el-form-item label="进货价">
              <el-input v-model="editForm.InPrice" placeholder="进货价"></el-input>
            </el-form-item>

            <el-form-item label="库存">
              <el-input v-model="editForm.Amount" placeholder="库存"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="批发价">
              <el-input v-model="editForm.OutPrice2" placeholder="批发价"></el-input>
            </el-form-item>

            <el-form-item label="起批量">
              <el-input v-model="editForm.MinAmount" placeholder="起批量"></el-input>
            </el-form-item>

            <el-form-item label="箱规">
              <el-input v-model="editForm.Spec" placeholder="箱规"></el-input>
            </el-form-item>
          </div>

          <div style="display: flex">
            <el-form-item label="一批商价">
              <el-input v-model="editForm.OutPrice" placeholder="一批商价"></el-input>
            </el-form-item>

            <el-form-item label="二批商价">
              <el-input v-model="editForm.OutPrice3" placeholder="二批商价"></el-input>
            </el-form-item>

            <el-form-item label="二批商价">
              <el-input v-model="editForm.Name2" placeholder="二批商价"></el-input>
            </el-form-item>
          </div>

          <el-form-item label="发布图片">
            <el-upload
              ref="upload"
              action="https://api.chajiuqu.com/api/current/uploadimage"
              list-type="picture-card"
              :file-list="myImage"
              :limit="9"
              :on-success="handleAvatarSuccess"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="审核">
            <el-input v-model="editForm.Status" placeholder="审核结果" disabled></el-input>
            <el-radio v-model="editForm.Status" :label="2">审核中</el-radio>
            <el-radio v-model="editForm.Status" :label="1">审核通过</el-radio>
            <el-radio v-model="editForm.Status" :label="4">审核退回</el-radio>
            <el-radio v-model="editForm.Status" :label="3">私密</el-radio>
            <el-radio v-model="editForm.Status" :label="0">删除</el-radio>
          </el-form-item>

          <el-form-item label="审核内容">
            <el-input type="textarea" v-model="editForm.AuditRemark"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="editCancel()">取 消</el-button>
          <el-button type="warning" @click="editDetermine()">确 定</el-button>
        </div>
      </template>

      <div style="min-height: 600px" v-else-if="activeName === '发布历史'">
        <el-table
          ref="TableListRef1"
          class="TableList"
          border
          :data="fabulist"
          v-loading="loading"
          v-if="activeName === '发布历史'"
        >
          <el-table-column prop="Name" label="商品名称" align="center" v-if="activeName === '发布历史'">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Name || '--' }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="Date" label="发布时间" align="center" v-if="activeName === '发布历史'">
            <template slot-scope="scope">
              <template>
                <span>{{ new Date(scope.row.Date) | parseTime }}</span>
              </template>
            </template>
          </el-table-column>

          <el-table-column prop="Status" label="公开/隐私" align="center">
            <template slot-scope="scope">
              {{ scope.row.Status === 3 ? '私密' : '公开' }}
            </template>
          </el-table-column>

          <el-table-column prop="BrowseCount" label="浏览量" align="center"></el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="fenye1">
          <el-pagination
            class="TablePagination"
            background
            @current-change="handleCurrentChange1"
            :current-page="goodsPageindex"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="goodsLength"
          ></el-pagination>
        </div>
      </div>

      <div style="min-height: 600px" v-else-if="activeName === '关注'">
        <FocusAndFans :loading="loading" :UserId="editForm.UserId"></FocusAndFans>
      </div>

      <div style="min-height: 600px" v-else-if="activeName === '粉丝'">
        <FocusAndFans :loading="loading" type="1" :UserId="editForm.UserId"></FocusAndFans>
      </div>

      <div style="min-height: 600px" v-else-if="activeName === '留言'">
        <MessageList :merchantsInfo="merchantsInfo" type="1" releaseType="1" :loading="loading"></MessageList>
      </div>

      <template v-else>
        <MessageList ref="InfoDialog" :merchantsInfo="merchantsInfo"></MessageList>

        <div slot="footer" class="dialog-footer">
          <el-button @click="editCancel()">取 消</el-button>
          <el-button type="warning" @click="editDetermine()">确 认</el-button>
        </div>
      </template>
    </el-dialog>

    <div class="pre_img" v-if="dialogVisible">
      <i class="el-icon-circle-close icon" @click="imgClose"></i>
      <el-image class="img" style="width: 20%; display: block; margin: 0 auto" :src="dialogImageUrl"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'membersIndex',
  data() {
    return {
      list: [],
      loading: true,
      beginDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      query: {
        pageindex: 1,
        pagesize: 20,
        type: 2,
        name: '',
        category1: '',
        loginName: '',
        storeAuditStatus: 2,
        status: null
      },
      total: 0, // 总条数

      editDialog: false,
      releaseDialog: false,
      editForm: {},
      rules: {},

      activeName: '商品发布',
      merchantsInfo: {},

      fabulist: [],
      goodsPageindex: 1,
      goodsLength: 0,

      checkBtnCurrent: 2,

      dialogImageUrl: '',
      dialogVisible: false,

      imgList: [],

      status: [
        {
          Status: 0,
          type: 'danger',
          text: '删除'
        },
        {
          Status: 1,
          type: 'success',
          text: '审核通过'
        },
        {
          Status: 2,
          type: 'primary',
          text: '提审中'
        },
        {
          Status: 3,
          type: 'warning',
          text: '私密'
        },
        {
          Status: 4,
          type: 'info',
          text: '退回'
        }
      ]
    }
  },
  created() {
    this.getList('place', 2)
  },
  computed: {
    myImage: function () {
      const arr = []
      if (this.editForm.DetailImage) {
        const list = this.editForm.DetailImage.split(',')
        list.forEach((item, index) => {
          arr.push({
            url: item,
            index: index
          })
        })
      }
      return arr
    }
  },
  methods: {
    // 初始化
    init() {
      this.query = {
        pageindex: 1,
        pagesize: 20,
        type: 2,
        name: '',
        category1: '',
        loginName: '',
        storeAuditStatus: 2,
        status: null
      }
      this.beginDate = []

      this.getList('place')
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleAvatarSuccess(res, file, fileList) {
      // console.log(res)
      // console.log(fileList)
      this.imageUrl = fileList

      this.imgList = []
      fileList.forEach((item) => {
        try {
          this.imgList.push(item.response.data)
        } catch (err) {
          this.imgList.push(item.url)
        }
      })
      console.log(this.imgList)
    },
    handleRemove(file, fileList) {
      this.imageUrl = fileList

      this.imgList = []
      fileList.forEach((item) => {
        try {
          this.imgList.push(item.response.data)
        } catch (err) {
          this.imgList.push(item.url)
        }
      })
      console.log(this.imgList)
      // console.log(this.imageUrl)
      // console.log(file, fileList)
    },

    imgClose() {
      this.dialogImageUrl = ''
      this.dialogVisible = false
    },

    handleClick() {},

    // 获取列表
    async getList(place, status) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        this.$nextTick(() => {
          this.$refs.TableListRef.bodyWrapper.scrollTop = 0
        })
      }

      if (!this.beginDate || !this.beginDate.length) {
        delete this.query.begintime
        delete this.query.endtime
      } else {
        this.query.beginTime = this.beginDate[0]
        this.query.endTime = this.beginDate[1]
      }

      if (status || status === 0) {
        this.query.status = status
        this.checkBtnCurrent = status
      }
      console.log('this.query', this.query)

      const { data: res } = await this.$api.release.goodsList(this.query)
      console.log('获取商圈发布', res)

      this.loading = false
      this.list = res.list
      this.total = res.count
    },

    async onEditInfo(item) {
      const form = this.$globalFun.deepClone(item)
      this.editForm = form

      this.imgList = this.editForm.DetailImage.split(',') || []

      const { data: res } = await this.$api.members.getStoreList({
        search: this.editForm.LoginName
      })
      this.merchantsInfo = res.list[0]
      console.log('获取商户资料', this.merchantsInfo)

      this.getGoodsList()

      this.editDialog = true
    },

    async getGoodsList(pageindex = 1) {
      const { data } = await this.$api.release.goodsList({
        loginName: this.editForm.LoginName,
        category1: '',
        name: '',
        pageindex,
        pagesize: 20,
        status: 1,
        storeAuditStatus: 2,
        type: 2
      })
      console.log('获取商户发布', data)

      this.fabulist = data.list
      this.goodsLength = data.count
    },

    editCancel() {
      this.editForm = {}
      this.editDialog = false
      this.activeName = '商品发布'
    },

    async editDetermine() {
      console.log(this.editForm)

      // if (this.imgList.length > 0) {
      //   console.log('111111111', this.editForm.DetailImage = this.imgList.join())
      // }

      this.editForm.DetailImage = this.imgList.join()

      const form = {
        Id: this.editForm.Id - 0,
        Status: this.editForm.Status - 0,
        Type: this.editForm.Type - 0,
        Spec: this.editForm.Spec - 0,
        MinAmount: this.editForm.MinAmount - 0,
        AuditRemark: this.editForm.AuditRemark,
        DetailImage: this.editForm.DetailImage,
        OutPrice: this.editForm.OutPrice - 0,
        OutPrice2: this.editForm.OutPrice2 - 0,
        OutPrice3: this.editForm.OutPrice3 - 0,
        Year: this.editForm.Year - 0,
        BoxCode: this.editForm.BoxCode,
        Code: this.editForm.Code,
        Name: this.editForm.Name,
        Name2: this.editForm.Name2,
        Remark: this.editForm.Remark,
        Price: this.editForm.Price - 0,
        InPrice: this.editForm.InPrice - 0
      }
      console.log(form)

      const { data: res } = await this.$api.release.goodsSave(this.editForm)
      console.log('修改', res)

      this.editForm = {}
      this.editDialog = false
      this.activeName = '商品发布'
      this.$message.success('修改成功')
      this.getList()
    },

    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },

    handleCurrentChange1(newPage) {
      this.goodsPageindex = newPage
      this.fabulist = []

      this.getGoodsList(newPage)
    },

    // 判断是否过期
    ifOverdue(time) {
      // console.log('time', time)
      const nowDate = new Date()
      const date = new Date(time).getTime() - nowDate.getTime()

      return date < 0
    }
  }
}
</script>

<style lang="scss" scoped>
.pre_img {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999988;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 60px;
    top: 60px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  .img {
    width: 20%;
    display: block;
  }
}
</style>
