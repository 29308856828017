<!--
 * @Description: 会员管理
-->
<template>
  <div>
    <div class="TableTop">
      <!-- 搜索 -->
      <div class="TableSearch">
        <el-form :model="query" @submit.native.prevent>
          <!-- 订单 -->
          <el-form-item label="订单：" class="form-item-b0">
            <el-input
              v-model="query.search"
              placeholder="请输入订单"
              size="small"
              clearable
              @keyup.native.enter="getList('place')"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="TableSearch_btn">
          <el-button type="primary" size="small" @click="getList('place')">筛选</el-button>
          <el-button size="small" @click="init()">重置</el-button>
          <el-button type="success" size="mini" @click="addDialog = true">添加产品</el-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="TableListRef" class="TableList" border :data="list" v-loading="loading">
      <el-table-column prop="ProductId" label="Id" align="center"></el-table-column>

      <el-table-column prop="Name" label="名称" align="center"></el-table-column>

      <el-table-column prop="Title" label="标题" align="center"></el-table-column>

      <el-table-column prop="Price" label="价格" align="center"></el-table-column>

      <el-table-column prop="Integral" label="积分" align="center"></el-table-column>

      <!-- <el-table-column prop="Content" label="详情" align="center"></el-table-column> -->

      <!-- <el-table-column prop="ImgUrl" label="图片" align="center">
        <template slot-scope="scope">
          <div class="auditImg" v-if="scope.row.ImgUrl">
            <div class="block" v-for="(item, index) in scope.row.ImgUrl.split(',')" :key="index">
              <img :src="item.Url" alt="" />
            </div>
          </div>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" align="center" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditInfo(scope.row)"> 修改信息 </el-button>

          <el-button style="margin-top: 6px" type="danger" size="mini" @click="onDelete(scope.row.ProductId)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fenye">
      <el-pagination
        class="TablePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageindex"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="query.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 商户管理 -->
    <el-dialog
      :visible.sync="addDialog"
      class="FormDialogBox"
      width="80%"
      title="添加产品"
      :close-on-click-modal="false"
      append-to-body
      @close="addDialogClose"
    >
      <el-form label-width="auto" :model="addForm" ref="ruleForm">
        <el-form-item label="产品标题">
          <el-input v-model="addForm.title" placeholder="产品标题"></el-input>
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="addForm.name" placeholder="产品名称"></el-input>
        </el-form-item>

        <el-form-item label="价格">
          <el-input v-model="addForm.price" placeholder="价格"></el-input>
        </el-form-item>

        <el-form-item label="积分">
          <el-input v-model="addForm.integral" placeholder="积分"></el-input>
        </el-form-item>

        <el-form-item label="运费">
          <el-input v-model="addForm.freight" placeholder="运费"></el-input>
        </el-form-item>

        <el-form-item label="兑换">
          <el-switch
            v-model="addForm.isIntegral"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>

          <span style="margin-left: 10px">(是否可以使用积分单独兑换)</span>
        </el-form-item>

        <el-form-item label="图片">
          <el-upload
            ref="upload"
            action="https://api.chajiuqu.com/api/current/uploadimage"
            list-type="picture-card"
            :file-list="myImage"
            multiple
            accept="image"
            :limit="9"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="状态">
          <el-switch
            v-model="addForm.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="详情">
          <!-- <el-input v-model="addForm.content" placeholder="详情"></el-input> -->
          <quill-editor
            class="ql-editor"
            v-model="addForm.content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
          >
          </quill-editor>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogClose">取 消</el-button>
        <el-button type="danger" @click="addDetermine()"> 确 定 </el-button>
      </div>
    </el-dialog>

    <el-upload
      ref="upload2"
      class="quill-avatar-uploader"
      style="display: none"
      action="https://api.chajiuqu.com/api/current/uploadimage"
      list-type="picture-card"
      :file-list="myImage"
      accept="image"
      :limit="1"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="imgChange"
    />

    <div class="pre_img" v-if="dialogVisible">
      <i class="el-icon-circle-close icon" @click="imgClose"></i>
      <el-image class="img" style="width: 20%; display: block; margin: 0 auto" :src="dialogImageUrl"></el-image>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor, Quill } from 'vue-quill-editor'

// 自定义 Blot
class CustomImageBlot extends Quill.import('blots/block/embed') {
  static create(value) {
    const node = super.create()
    const { width, height } = value

    node.setAttribute('src', value.url)
    node.setAttribute('style', `width: ${width || '100%'}; height: ${height || 'auto'};`)
    console.log('-------------------------', node)

    return node
  }

  static value(node) {
    return { url: node.getAttribute('src') }
  }
}
CustomImageBlot.blotName = 'customImage'
CustomImageBlot.tagName = 'img'
Quill.register(CustomImageBlot)

export default {
  name: 'membersIndex',

  components: {
    quillEditor
  },

  data() {
    return {
      // 富文本功能配置
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'], // 引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: 'ordered' }, { list: 'bullet' }], // 列表
              [{ script: 'sub' }, { script: 'super' }], // 上下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ font: [] }], // 字体
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除字体样式
              ['image'] // 上传图片、上传视频
            ],

            handlers: {
              image: (value) => {
                // const upload = document.querySelector('.quill-avatar-uploader input')
                // upload.click()

                if (value) {
                  this.$refs.upload2.$el.childNodes[1].click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      },
      dialogImageUrl: '',
      dialogVisible: false,

      list: [],

      addDialog: false,
      addForm: {
        id: 0,
        name: '',
        title: '',
        price: '',
        integral: '',
        freight: '',
        isIntegral: 1,
        ImgUrl: '',
        content: '',
        status: 1
      },

      loading: true,
      total: 0,

      query: {
        pageindex: 1,
        pagesize: 20,
        search: ''
      }
    }
  },

  created() {
    this.getList('place')
  },

  computed: {
    myImage: function () {
      const arr = []
      if (this.addForm.ImgUrl) {
        const list = this.addForm.ImgUrl.split(',')
        list.forEach((item) => {
          if (item) {
            arr.push({
              url: item
            })
          }
        })
      }
      return arr
    }
  },

  methods: {
    imgChange(file) {
      console.log(file)
      if (file.data) {
        const url = file.data.replace('http:', 'https:')
        this.insertImg(url, 'image')

        this.$refs.upload2.clearFiles()
      } else {
        this.$message.error('插入图片失败')
      }
    },
    uploadImage(e) {
      console.log(e)
    },
    // 事件方法
    onEditorBlur(e) {
      console.log(e, '失去焦点事件')
    },
    onEditorFocus(e) {
      console.log(e, '获得焦点事件')
    },
    onEditorChange(e) {
      console.log(e, '内容改变事件')
      // console.log(e.html)
    },
    insertImg(url, type) {
      // 插入图片
      const quill = this.$refs.myQuillEditor.quill
      const length = quill.getSelection().index

      const imgHtml = `<img src="${url}" style="width:100%; height:auto;">`
      console.log(url, imgHtml)

      quill.insertEmbed(length, 'customImage', {
        url,
        width: '100%',
        height: 'auto'
      })
      quill.setSelection(length + 1)
    },
    // 获取列表
    async getList(place) {
      // 重置页数
      if (place) {
        this.query.pageindex = 1
        // 滚动条回到顶部
        await this.$nextTick()
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      }

      console.log(this.query)

      try {
        const { data: res } = await this.$api.integral.list(this.query)
        console.log('获取订单列表', res)

        this.loading = false
        this.list = res.list
        this.total = res.count
      } catch (error) {
        console.log(error)
        this.$message.error('获取列表失败')
        this.loading = false
      }
    },

    onEditInfo(data) {
      this.addForm = {
        id: data.ProductId,
        name: data.Name,
        title: data.Title,
        price: data.Price,
        integral: data.Integral,
        freight: data.Freight,
        isIntegral: data.IsIntegral,
        ImgUrl: data.ImgUrl,
        content: data.Content,
        status: data.Status
      }
      this.addDialog = true
    },

    async onDelete(id) {
      this.$confirm('是否删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$api.integral.delete({
          id
        })
        console.log('删除产品', data)

        this.$message.success('删除成功')
        this.getList()
      })
    },

    async addDetermine() {
      console.log('addForm', this.addForm)
      console.log('imgUrls', this.$refs.upload.uploadFiles)
      // if (a) return
      const imgUrl = this.$refs.upload.uploadFiles
        .map((item) => {
          const url = item.response ? item.response.data : item.status === 'success' ? item.url : ''
          return url
        })
        .join(',')
      this.addForm.imgUrl = imgUrl

      const img = this.$refs.myQuillEditor.quill.root.querySelector('img')
      console.log(img)

      const { data } = await this.$api.integral.add(this.addForm)
      console.log('添加产品', data)

      this.addDialog = false
      this.addForm = {
        id: 0,
        name: '',
        title: '',
        price: '',
        integral: '',
        freight: '',
        isIntegral: 1,
        ImgUrl: '',
        content: '',
        status: 1
      }
      this.getList()

      this.$message.success('添加成功')
    },

    addDialogClose() {
      this.addForm = {
        id: 0,
        name: '',
        title: '',
        price: '',
        integral: '',
        freight: '',
        isIntegral: 1,
        ImgUrl: '',
        content: '',
        status: 1
      }

      this.addDialog = false
    },

    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      console.log(file.url)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    imgClose() {
      this.dialogImageUrl = ''
      this.dialogVisible = false
    },

    // 每页显示条数和当前页码
    handleSizeChange(newSize) {
      this.query.pagesize = newSize
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    },
    handleCurrentChange(newPage) {
      this.query.pageindex = newPage
      this.getList()
      this.$nextTick(() => {
        this.$refs.TableListRef.bodyWrapper.scrollTop = 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .data-list {
  width: 620px !important;

  ul.el-scrollbar__view {
    display: flex;
    flex-wrap: wrap;
  }
}

.auditImg {
  display: flex;
  .block {
    margin-right: 25px;
    span {
      display: block;
      width: 120px;
      text-align: center;
    }
    img {
      width: 120px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.pre_img {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999988;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 60px;
    top: 60px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  .img {
    width: 20%;
    display: block;
  }
}
</style>
