import http from '../request.js'

export default {
  list: (data) => {
    return http({
      url: '/api/order/list',
      data
      // aaaaaa: 111
    })
  },

  add: (data) => {
    return http({
      url: '/api/order/save',
      data
    })
  },

  delete: (data) => {
    return http({
      url: '/api/order/delete',
      data
    })
  }
}
